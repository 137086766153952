import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import policiesMainIcon from '../images/policies-main-icon.svg';
import PoliciesContent from '../components/PoliciesContent';
import HeroBannerSubpage from '../components/HeroBannerSubpage';

const Terms = () => (
  <Layout page="cookies">
    <HeroBannerSubpage
      title="Cookie Policy"
      subtitle="Entracker Cookie Policy"
      image={policiesMainIcon}
    />
    <SEO
      title="Cookie Policy"
      description="Drawing centric project management application"
      keywords={['cookies', 'detailers', 'engineers']}
    />
    <PoliciesContent key="cookies" type="cookies" />
  </Layout>
)

export default Terms;
